<template>
  <div class="columns is-multiline is-mobile">
    <!-- First row -->
    <div class="column is-4">
      <GridItem
        :flag="data.stolen"
        text="Stolen"
        alt-text="Not stolen"
        danger
      />
    </div>
    <div class="column is-4">
      <GridItem
        :flag="data.risk"
        text="High risk"
        alt-text="Not high risk"
        danger
      />
    </div>
    <div class="column is-4">
      <GridItem
        :flag="data.finance"
        text="Finance reported"
        alt-text="No finance"
        warning
      />
    </div>

    <div class="column is-12">
      <hr class="hr is-marginless">
    </div>

    <!-- Second row -->
    <div class="column is-4">
      <GridItem
        :flag="data.colourChanges"
        text="Colour changes"
        alt-text="No colour changes"
        warning
      />
    </div>
    <div class="column is-4">
      <GridItem
        :flag="data.scrapped"
        text="Scrapped"
        alt-text="Not scrapped"
        danger
      />
    </div>
    <div class="column is-4">
      <GridItem
        :flag="data.writeOff"
        text="Written off"
        alt-text="Not written off"
        danger
      />
    </div>

    <div class="column is-12">
      <hr class="hr is-marginless">
    </div>

    <!-- Third row -->
    <div class="column is-4">
      <GridItem
        :flag="data.mileage"
        text="Mileage issues"
        alt-text="No mileage issues"
        warning
      />
    </div>
    <div class="column is-4">
      <GridItem
        :flag="data.keepers"
        text="Keeper issues"
        alt-text="No keeper issues"
        warning
      />
    </div>

    <div class="column is-4">
      <GridItem
        :flag="data.motHistory"
        text="MOT issues"
        alt-text="No MOT issues"
        warning
      />
    </div>

    <div class="column is-12">
      <hr class="hr is-marginless">
    </div>

    <!-- Fourth row -->
    <div class="column is-4">
      <GridItem
        :flag="data.taxAndSorn"
        text="Tax/SORN issues"
        alt-text="No tax issues"
        warning
      />
    </div>
    <div class="column is-4">
      <GridItem
        :flag="data.plateChange"
        text="Plate changes"
        alt-text="No plate changes"
        warning
      />
    </div>
    <div class="column is-4">
      <GridItem
        :flag="data.conditionAlerts"
        text="Condition alerts"
        alt-text="No condition alerts"
        warning
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MotorCheckGrid',
  components: {
    GridItem: {
      props: {
        flag: Boolean,
        text: String,
        altText: String,
        danger: Boolean,
        warning: Boolean
      },
      template: `
        <div class="columns is-vcentered">
          <div class="column is-narrow">
            <button 
              class="button is-centered" 
              :class="[
                flag ? (
                  danger ? 'is-danger' : 
                  warning ? 'is-warning' : 
                  'is-success'
                ) : 'is-success'
              ]"
              style="width: 24px; height: 24px; padding: 0;"
            >
            </button>
          </div>
          <div class="column">
            <span class="is-flex is-align-items-center" style="height: 24px;">
              {{ flag ? text : altText }}
            </span>
          </div>
        </div>
      `
    }
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>
